import React from 'react';
import {NavLink} from 'react-router-dom'
import '../assets/App.css'
import logo from '../assets/img/logindashboard.png';
import logoAnalisa from '../assets/img/analisa3.png';
import facebook from '../assets/iconos/icons8-facebook-48.png';
import youtube from '../assets/iconos/icons8-play-button-48.png';
import twwiter from '../assets/iconos/icons8-twitter-48.png';




export default function Nav({usuario, logout, login}) {
    return (
        <nav>
            <ul>
                {usuario && <LoginRoutes usuario={usuario} logout={logout} login={login}/>}
            </ul>
        </nav>
    )
}

function LoginRoutes({usuario, logout, login}) {
    return (
        <>
            <nav className="bg-gray-400 fixed inset-x-0 top-0 left-0  py-1">
                <div className="px-2 sm:px-6 lg:px-8 -mt-8">
                    <div className="flex items-center h-1/6">
                        <div className="flex-shrink-0 flex items-center">
                            <img className="block lg:hidden h-20 w-auto"
                                 src={logo}/>
                        </div>
                        <div className="flex-1 flex  justify-center">
                            <div className="space-x-4">
                                <img className="block lg:hidden h-6/6 w-64 -ml-26"
                                     src={logoAnalisa}/>
                            </div>
                        </div>
                        <div className="space-x-4">
                            <NavLink activeClassName="false"  to={{ pathname: "https://www.facebook.com/CRCCol" }}  target="_blank">
                                <img src={facebook} alt="Facebook CRC" />
                            </NavLink>
                        </div>
                        <div className="space-x-4">
                            <NavLink activeClassName="false"    to={{ pathname: "https://twitter.com/CRCCol" }}  target="_blank">
                                <img src={twwiter} alt=""/>
                            </NavLink>
                        </div>
                        <div className="space-x-4">
                            <NavLink activeClassName="false"    to={{ pathname: "https://www.youtube.com/user/CRCCol" }}  target="_blank">
                                <img src={youtube} alt=""/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="bg-gray-400 fixed inset-x-0 top-25 left-0  py-1">
                <div className="px-2 sm:px-6 lg:px-8">
                    <div className="flex items-center h-1/6">
                        <div className="flex-1 flex  justify-center">

                            <div className="hidden sm:block ml-40">
                                <div className="space-x-4">
                                    <NavLink activeClassName="active" to="/buscar"
                                             className="text-black hover:bg-rosa hover:text-white text-xl px-3 py-2 rounded-md text-sm">
                                        Buscar
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/cargar">
                                        <a
                                            className="text-black hover:bg-rosa hover:text-white px-3 py-2 rounded-md text-sm text-xl	">Cargar</a>
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/ocr">
                                        <a
                                            className="text-black hover:bg-rosa hover:text-white px-3 py-2 rounded-md text-sm text-xl	">OCR</a>
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/historia">
                                        <a
                                            className="text-black hover:bg-rosa hover:text-white px-3 py-2 rounded-md text-sm text-xl">Historia</a>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div
                            className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


                            <div className="ml-3 relative">
                                <div>
                                    <button type="button"
                                            className="flex  text-white px-3 py-1 border font-medium rounded">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
                                        </svg>
                                        <NavLink activeClassName="active" to="/crc"
                                                 className="ml-8 h-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-rosa hover:bg-gris">

                                            <BotonLogout logout={logout}></BotonLogout>
                                        </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>


            {/*<Link  className="Nav__link">*/}
            {/*    <img className="block lg:hidden h-20 w-auto"*/}
            {/*         src={logo}/>*/}
            {/*    <img className="hidden lg:block h-20 w-auto"*/}
            {/*         src={logo}*/}
            {/*         alt="Workflow"/>*/}
            {/*</Link>*/}
            {/*<li className="Nav__link-push">*/}
            {/*    <Link className="Nav__link" to="/buscar">*/}
            {/*        <FontAwesomeIcon icon={faCameraRetro}>*/}

            {/*        </FontAwesomeIcon>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li className="Nav__link-margin-left">*/}
            {/*    <Link className="Nav__link"  to={{*/}
            {/*        pathname: "/cargar",*/}
            {/*    }}>*/}
            {/*        <FontAwesomeIcon icon={faCompass}/>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li className="Nav__link-margin-left">*/}
            {/*    <Link className="Nav__link" to="/ocr">*/}
            {/*        <FontAwesomeIcon icon={faCompass}/>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li className="Nav__link-margin-left">*/}
            {/*    <Link className="Nav__link" to="/historia">*/}
            {/*        <FontAwesomeIcon icon={faAddressBook}/>*/}
            {/*    </Link>*/}
            {/*</li>*/}
            {/*<li className="Nav__link-margin-left">*/}
            {/*    <Link className="Nav__link" to="/crc">*/}
            {/*        <BotonLogout logout={logout}></BotonLogout>*/}
            {/*    </Link>*/}
            {/*</li>*/}
        </>
    )
}


function BotonLogout({logout}) {
    return (
        <button onClick={logout}>
            Salir
        </button>
    )
}
