import React, {useEffect, useState} from 'react';
import './assets/App.css';
import Login from "./componentes/Login"
import Axios from "axios";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {deleteToken, getToken, initAxiosInterceptors, setToken} from "./Helpers/auth-helpers";
import Error from "./componentes/Error";
import Buscar from "./componentes/Buscar";
import Cargar from "./componentes/Cargar";
import ORC from "./componentes/OCR";
import Historia from "./componentes/Historia";
import Nav from "./Vistas/Nav";


//Apenas cargue el navegador llamamos esta funcion
initAxiosInterceptors();

/**
 * Constantes
 */
const ROL_CONSULTA = "ROLE_CONSULTA";
const ROL_CARGA = "ROLE_CARGA";
const ROL_HISTORIA = "ROLE_HISTORIA";
const NUM_ROLES = 3;
export default function App() {


    /*
    Nuevas Variables
     */
    //No sabemos si hay un usuario autenticado
    const [usuario, setUsuario] = useState(null);

    //saber esl estado del usuario
    const [cargandoUsuario, setCargandoUsuario] = useState(true);

    //contener los errores
    const [error, setError] = useState(null);


    /**
     * funcion a cambiar con el token
     * antes -> api/public/login
     * ahora-> /api/consultar/temas
     */
    useEffect(() => {

        async function cargarUsuario() {
            //validamos si existe un token
            if (!(getToken())) {
                setCargandoUsuario(false);
                return;
            }
            try {

                const datos = {
                    'jwt': getToken(),
                }
                const {data: usuarioJWT} = await Axios.post('api/public/login/jwt', datos);
                setUsuario(usuarioJWT);
                setCargandoUsuario(false);
            } catch (error) {
                deleteToken();
                window.location = '/crc';

            }

        }

        cargarUsuario();

    }, []);

    /**
     *  data.usuario
     *  data.token
     * @param email
     * @param password
     * @returns {Promise<void>}
     */
    async function login(user, password) {
        const {data} = await Axios.post('api/public/login', {
                user,
                password,

            }
        );

        if (data.loginExitoso === true && data.roles.length > 0) {
            if (data.roles.length === NUM_ROLES) {
                console.log('1');
                setUsuario('1');
            } else {
                if (data.roles[0] === ROL_HISTORIA) {
                    console.log('4');
                    setUsuario('4');
                } else if (data.roles[0] === ROL_CARGA) {
                    console.log('3');
                    setUsuario('3');
                } else if (data.roles[0] === ROL_CONSULTA) {
                    console.log('2');
                    setUsuario('2');
                } else {
                    console.log('0');
                    setUsuario('0');
                }
            }
        } else {
            setUsuario('0');
        }

        /**
         * Asiganomos el Token al sistema para consultas.
         */
        setToken(data.jwt);

    }


    const clearInputs = () => {
        setUsuario('');
    }

    // const clearErrors = () => {
    //     setemailError('');
    //     setpasswordError('');
    // }


    /**
     * Funcion para salir de la aplicación
     */
    function logout() {
        setUsuario(null);
        deleteToken();
    }

    /**
     * Funcion para mostrar errores
     */
    function mostrarError(mensaje) {
        setError(mensaje);
    }

    /**
     * Funcion para esconder el boton del error
     */
    function esconderError() {
        setError(null);
    }


    return (
        <Router>
            <Nav usuario={usuario} logout={logout} login={login}/>
            {
                usuario ? (<LoginRoutes mostrarError={mostrarError} usuario={usuario} logout={logout}/>) : (
                    <LogoutRoutes login={login}/>)
            }
        </Router>

    );


}

//Nuevos componentes de las rutas del usuario que este autenticado
function LoginRoutes({mostrarError, usuario, logout, login}) {
    return (
        <Switch>
            <Route
                path="/historia"
                render={props => <Historia {...props} mostrarError={mostrarError}></Historia>}/>} />
            <Route
                path="/cargar"
                render={props => <Cargar {...props} mostrarError={mostrarError} usuario={usuario}></Cargar>}/>} />
            <Route
                path="/ocr"
                render={props => <ORC {...props} mostrarError={mostrarError} usuario={usuario} logout={logout}></ORC>}/>}
            />
            <Route
                path="/"
                render={props => <Buscar {...props} mostrarError={mostrarError} usuario={usuario}
                                         logout={logout}></Buscar>}/>}
            />
        </Switch>
    )

}


//Render las rutas cuando no esta autenticado
function LogoutRoutes({login}) {
    return (
        <Switch>
            <Route
                path="/crc/"

                render={props => <Login {...props} login={login}></Login>}/>} />
        </Switch>
    )
}





