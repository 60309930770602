import React, {useState} from 'react';
import Axios from "axios";
import ReactNotification, {store} from "react-notifications-component";
import logoColombia from '../assets/img/GOBIERNO-DE-COLOMBIA-2020.png';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ORC = ({mostrarError, usuario}) => {
    /**
     * Estado para el archivo subido
     * @param e
     */
    const [archivo, setArchivo] = useState('');
    const [archivoNombre, setArchivoNombre] = useState('');
    const [archivotype, setArchivotype] = useState('');
    const [botonCargando, setBotonCargando] = useState('');
    const [cargandoDatosEnvio, setCargandoDatosEnvio] = useState(false);


    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onChangeArchivo = async e => {
        var arrayAuxiliar = [];
        const archivoByte = e.target.files[0];
        setArchivotype(archivoByte);
        const base64 = await convertirBase64(archivoByte);
        arrayAuxiliar = base64.split(',');
        const nombreArchivo = archivoByte.name;
        setArchivo(arrayAuxiliar[1]);
        setArchivoNombre(nombreArchivo);

    };

    /**
     * Función anonima que nos permite
     * convertir un archivo a Base64
     * @param e
     * @returns {Promise<void>}
     */
    const convertirBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const archivoLeido = new FileReader();
            archivoLeido.readAsDataURL(archivo);
            archivoLeido.onload = (() => {
                resolve(archivoLeido.result);
            });
            archivoLeido.onerror = ((error) => {
                reject(error);
            });

        });
    }


    /**
     *
     * @param evento
     * @returns {Promise<void>}
     */
    async function handleEnviandoCarga(evento) {
        evento.preventDefault();
        try {
            if (archivo != '') {

                const datos = {
                    'tipoCarga': "ocr",
                    'fileName': archivoNombre,
                    'file': archivo,

                }
                setBotonCargando('loading');
                setCargandoDatosEnvio(true);
                const {data} = await Axios.post('api/cargar', datos);
                setCargandoDatosEnvio(false);
                const notificationExitosa = {
                    title: "Exitoso!",
                    message: "Se ha empezado a ejecutar la carga solicitada.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                setBotonCargando('success');
                store.addNotification({
                    ...notificationExitosa,
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                clearInputs();
            } else {
                const notificationDatosNulos = {
                    title: "Aviso!",
                    message: "Seleccione un archivo",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                store.addNotification({
                    ...notificationDatosNulos,
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                setBotonCargando('error');
                clearInputs();
            }


        } catch (error) {
            const notificationError = {
                title: "Error!",
                message: "Error cargando el archivo.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };
            store.addNotification({
                ...notificationError,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            setBotonCargando('error');
            clearInputs();

        }
    }

    /**
     *
     */
    const clearInputs = () => {
        setArchivo('');
        setArchivoNombre('');
        setArchivotype('');

    }


    return (
        <form onSubmit={handleEnviandoCarga}>
            <ReactNotification></ReactNotification>
            <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full mt-20">
                <div className="md:container md:mx-auto bg-gray-100">
                <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8 ">
                    <div className="border-azul bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mb-6 mt-16">
                        <div className="flex justify-center text-sm text-gray-700">
                            <div className="mb-4">
                                <div className="w-auto mr-10 text-center">
                                    <h3 className="pb-2 border-b mb-3 text-lg  font-black">Escoja un archivo
                                        escaneado (PDF) para que el sistema lo convierta en texto</h3>

                                </div>
                                <div className="bg-white p-6  rounded-lg flex justify-center items-center ml-52">

                                    <div className="p-0.5">
                                        <div
                                            className="bg-white h-10 flex items-center rounded-full shadow-xl border border-rosa cursor-pointer">
                                            <input value={archivoNombre} readOnly disabled
                                                   className="rounded-l-full appearance-none w-96 py-4 px-6 text-black leading-tight focus:outline-none"
                                                   onChange={onChangeArchivo}/>

                                            <div className="p-4">
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full w-50 mr-60 px-8 py-0">
                                        <label

                                            className="ml-8 h-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-rosa hover:bg-gris">
                                            <svg className="w-16 h-10" fill="currentColor"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 20 20">
                                                <path
                                                    d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"/>
                                            </svg>
                                            <span className="mt-2 text-base leading-normal text-center">Selecionar archivo</span>
                                            <input accept="application/pdf" type='file' className="hidden"
                                                   onChange={onChangeArchivo}/>
                                        </label>


                                    </div>
                                </div>
                                <div className="border-b text-center mt-4">
                                    <div className="rounded-full w-46 mr-60 px-24 py-0">
                                        <label
                                            className="ml-8 whitespace-nowrap inline-flex items-center ml-80 h-12 justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-azul hover:bg-rosa">
                                            {
                                                !cargandoDatosEnvio && (
                                                    <button
                                                        className="hover:opacity-75 text-white rounded-full mb-14 mr-4 w-8 h-8 cursor-pointer"
                                                        onClick={handleEnviandoCarga}
                                                    >
                                                        <svg className="mt-4 w-6 h-6" xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20"
                                                             fill="currentColor">
                                                            <path fill-rule="evenodd"
                                                                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                                                  clip-rule="evenodd"/>
                                                        </svg>
                                                    </button>)
                                            }

                                            <span className="sm:mt-2 text-base leading-normal text-center">Convertir a texto</span>

                                            {
                                                cargandoDatosEnvio && (
                                                    <svg className="animate-spin h-6 w-6 text-center mt-8 ml-6 "
                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              strokeWidth={2}
                                                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                                                    </svg>
                                                )
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
                </div>
                <div className="bg-gray-200 border-t mt-auto">
                    <div className="container mx-auto px-4">
                        <div className="md:flex justify-between  items-center text-sm">
                            <div className="text-center md:text-left py-3 md:py-4 border-b md:border-b-0">
                                <div className="text-rosa text-center md:mr-4">&copy; 2020 anaLIsA</div>
                            </div>
                            <div className="md:flex md:flex-row-reverse items-center py-4">
                                <div className="text-grey text-center md:mr-4">
                                    <img className="max-h-20 -mr-28" src={logoColombia}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    )

}

export default ORC