import React, {useEffect, useState} from 'react';
import '../assets/main.css';
import '../assets/App.css';
import Axios from "axios";
import ReactNotification from "react-notifications-component";
import Loading from "./Loading";
import {Button, ConfigProvider, Modal, Table} from "antd";
import "antd/dist/antd.css";
import espanol from 'antd/lib/locale/es_ES';
import {FilePdfTwoTone, PlusCircleTwoTone, SearchOutlined} from '@ant-design/icons';
import logoColombia from '../assets/img/GOBIERNO-DE-COLOMBIA-2020.png';



/**
 *
 * @param valor
 * @returns {JSX.Element}
 * @constructor
 */
function Icono({valor}) {
    switch (valor) {
        case 'search':
            return <SearchOutlined style={{fontSize: '20px', color: '#E62954'}}/>
        case 'upload':
            return <FilePdfTwoTone style={{fontSize: '20px', color: '#0000ff'}}/>
        case 'ocr':
            return <PlusCircleTwoTone style={{fontSize: '20px', color: '#F2D922'}}/>

        default:
            return <PlusCircleTwoTone style={{fontSize: '20px', color: '#603bbb'}}/>
    }

}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Historia = ({mostrarError, usuario}) => {

    /**
     * Variables de Estado
     */
    const [cargandoHistoria, setCargandoHistoria] = useState(true);
    const [historias, setHistorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [historicoConsulta, setHistoricoConsulta] = useState([]);

    /**
     *
     */
    useEffect(() => {
        async function cargarHistorias() {
            try {
                setCargandoHistoria(true);
                const {data: data} = await Axios.get('api/historia');
                setHistorias(data.data);
                setLoading(false);
                // setCargandoHistoria(false);
            } catch (error) {
                if (error.response && (error.response.status === 404 || error.response.status === 400)) {
                    // mostrarError('Hubo un problema cargando las Historias.');
                } else {
                    // mostrarError('Hubo un problema cargando las Historias.');
                }
                setCargandoHistoria(false);

            }
        }

        cargarHistorias();
    }, []);


    // if (loading) {
    //     return (
    //
    //         <Loading/>
    //     )
    // }


    /**
     *      * @param idUUID
     * @returns {Promise<void>}
     */
    async function buscarHistoria(idUUID) {
        try {
            const {data: data} = await Axios.get(`/api/historia/${"2514d975-6947-4bb9-ade5-6c17b5800b55"}`);


            console.log(data.resultados)
            setHistoricoConsulta(data.resultados);
            setVisible(true)
        } catch (error) {
            console.log(error);

        }
    }


    /**
     * Primera columna para los datos del historico
     * @type {({dataIndex: string, width: number, title: string, align: string, render: (function(*)), key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string})[]}
     */
    const columnas = [
        {
            title: "Acción",
            dataIndex: "type",
            key: "type",
            width: 100,
            align: "center",
            render: type => <Button> <Icono valor={type}></Icono></Button>

        },
        {
            title: "Archivo",
            dataIndex: "name",
            key: "name",

        },
        {
            title: "Fecha",
            dataIndex: "time",
            key: "time"
        },
        {
            title: "Tiempo",
            dataIndex: "when",
            key: "when"
        }

    ];

    /**
     *
     * @type {({dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, render: (function(*)), key: string})[]}
     */
    const columnasResultado = [

        {
            title: "Nombre ",
            dataIndex: "nombre",
            key: "nombre",

        },
        {
            title: "Puntaje",
            dataIndex: "puntaje",
            key: "puntaje"
        }
        ,
        {
            title: "Fecha",
            dataIndex: "fecha",
            key: "fecha"
        },
        {
            title: "URL",
            dataIndex: "url",
            key: "url",
            render: url => <a href={url} target="_blank">{url}</a>

        },

    ];


    /**
     * Funcion para obtener el valor e identificador de la fial
     * @param record
     * @param rowIndex
     */
    const handleValorFila = (record, rowIndex) => {
        if (record.uuid !== null && record.type !== null) {
            console.log(record)
            buscarHistoria(record.uuid);
        } else {
            return null;
        }

    };
    return (
        <ConfigProvider locale={espanol}>
            <ReactNotification></ReactNotification>
            <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full mt-20">
                <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8 ">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mt-16 bg-gray-300">
                            <div className="w-auto mr-10 text-center">
                                <h3 className="pb-2 border-b mb-3 text-lg  font-black">Historia</h3>
                            </div>
                            <table className="min-w-full leading-normal">
                                <Table rowKey="uuid"
                                       bordered={true}
                                       columns={columnas} dataSource={historias} pagination={{pageSize: 10}}
                                       scroll={{y: 270}}

                                       onRow={(record, rowIndex) => {
                                           return {
                                               onClick: event => {
                                                   handleValorFila(record, rowIndex)
                                               }, // click row
                                           };
                                       }}
                                />,

                            </ table>

                        {
                            <>
                                <Modal
                                    title="Resultado"
                                    closable={false}
                                    visible={visible}
                                    centered
                                    onOk={() => setVisible(false)}
                                    onCancel={() => setVisible(false)}
                                    width={1000}
                                    wrapClassName="vertical-center-modal"


                                >


                                    <Table rowKey="url"
                                           bordered={true}
                                           columns={columnasResultado} dataSource={historicoConsulta}
                                           pagination={{pageSize: 10}} scroll={{y: 370}}


                                    />


                                </Modal>
                            </>
                        }


                    </div>

                </div>
                <div className="bg-gray-200 border-t">
                    <div className="container mx-auto px-4">
                        <div className="md:flex justify-between  items-center text-sm">
                            <div className="text-center md:text-left py-3 md:py-4 border-b md:border-b-0">
                                <div className="text-rosa text-center md:mr-4">&copy; 2020 anaLIsA</div>
                            </div>
                            <div className="md:flex md:flex-row-reverse items-center py-4">
                                <div className="text-grey text-center md:mr-4">
                                    <img className="max-h-20 -mr-28" src={logoColombia}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ConfigProvider>

    )
}


export default Historia