import React, {useEffect, useState} from 'react'
import '../assets/react-progress-button.css';
import Axios from "axios";
import ReactNotification, {store} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import logoColombia from '../assets/img/GOBIERNO-DE-COLOMBIA-2020.png';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Cargar = ({mostrarError, usuario}) => {
    /**
     * Estado para el archivo subido
     * @param e
     */
    const [archivo, setArchivo] = useState('');
    const [archivoNombre, setArchivoNombre] = useState('');
    const [archivotype, setArchivotype] = useState('');
    const [botonCargando, setBotonCargando] = useState('');
    const [botonCargandoControl, setBotonCargandoControl] = useState(true);
    const [mostrarTabla, setMostrarTabla] = useState(false);
    /**
     * Evitamos que el usuario le de multiples veces al botón
     */
    const [cargandoDatosEnvio, setCargandoDatosEnvio] = useState(false);


    const [temasSubTemasEntradas, setTemasSubTemasEntradas] = useState([{
        tema: 'Uno',
        subtema: []
    },]);

    const [tema, setTema] = useState([]);

    const [subtemas, setSubtemas] = useState([]);

    const [subtemasBandeja, setSubtemasBandeja] = useState(false);

    const [cargarListTemas, setCargarListTemas] = useState(false);

    const [valorTemaSelecionado, setValorTemaSelecionado] = useState('');
    const [valorTemaSubSelecionado, setValorTemaSubSelecionado] = useState([]);

    const onChangeArchivo = async e => {
        var arrayAuxiliar = [];
        const archivoByte = e.target.files[0];
        setArchivotype(archivoByte);
        const base64 = await convertirBase64(archivoByte);
        arrayAuxiliar = base64.split(',');
        const nombreArchivo = archivoByte.name;
        setArchivo(arrayAuxiliar[1]);
        setArchivoNombre(nombreArchivo);

    };

    /**
     * Función anonima que nos permite
     * convertir un archivo a Base64
     * @param e
     * @returns {Promise<void>}
     */
    const convertirBase64 = (archivo) => {
        return new Promise((resolve, reject) => {
            const archivoLeido = new FileReader();
            archivoLeido.readAsDataURL(archivo);
            archivoLeido.onload = (() => {
                resolve(archivoLeido.result);
            });
            archivoLeido.onerror = ((error) => {
                reject(error);
            });

        });
    }


    /**
     * Cargar Archivo
     */

    async function handleEnviandoCarga(evento) {
        evento.preventDefault();
        try {
            if (archivo !== '' && Object.entries(temasSubTemasEntradas).length !== 0) {
                const datos = {
                    'tipoCarga': "upload",
                    'fileName': archivoNombre,
                    'file': archivo,
                    'temas': temasSubTemasEntradas,
                }
                setBotonCargando('loading');
                setCargandoDatosEnvio(true);
                const {data} = await Axios.post('api/cargar', datos);
                setCargandoDatosEnvio(false);
                const notificationExitosa = {
                    title: "Exitoso!",
                    message: "Archivo cargado en el sistema.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                setBotonCargando('success');
                store.addNotification({
                    ...notificationExitosa,
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                clearInputs();
            } else {
                const notificationDatosNulos = {
                    title: "Aviso!",
                    message: "Seleccione un archivo",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                store.addNotification({
                    ...notificationDatosNulos,
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                setBotonCargando('error');
                clearInputs();
            }


        } catch (error) {
            const notificationError = {
                title: "Error!",
                message: "Error cargando el archivo.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };
            store.addNotification({
                ...notificationError,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            setBotonCargando('error');
            clearInputs();

        }
    }

    const clearInputs = () => {
        setArchivo('');
        setArchivoNombre('');
        setArchivotype('');

    }

    /**
     * Tabla de busquedas
     */
    useEffect(() => {
        async function cargarTemasIniciales() {
            try {
                const nuevosTemas = await cargarTemas();
                setTema(nuevosTemas);
                console.log(nuevosTemas);
            } catch (error) {
                console.log(error);

            }
        }

        cargarTemasIniciales();
    }, []);

    async function cargarTemas() {
        const {data} = await Axios.get("api/consultar/temas");
        // options = data.temas.map(({ idTema, descripcion }) => ({ value: idTema, label: descripcion }));
        // console.log('cargando temas ', options);
        return data;
    }

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    async function cargarSubTemas(e) {
        e.preventDefault();

        const idSubtema = e.target.value;

        const temaSeleciona = tema.temas.filter(element => element.idTema == idSubtema);
        const temaSelecionaDescripcion = temaSeleciona.map((temass) => (
            temass.descripcion
        ));
        setValorTemaSelecionado(temaSelecionaDescripcion)
        try {
            const {data} = await Axios.get(`api/consultar/tema/${idSubtema}`);
            setSubtemas(data);
            setSubtemasBandeja(true)
            console.log(subtemas)
        } catch (error) {
            console.log(error);

        }
    }

    /**
     *
     * @param event
     */
    const handleChangeSubtemas = (event) => {
        event.preventDefault();

        const idSubtema = event.target.value;
        console.log(idSubtema)
        setValorTemaSubSelecionado(idSubtema);
        console.log(valorTemaSubSelecionado)

    }
    /**
     *
     * @param index
     * @param event
     */
    const handleChangeInput = (index, event) => {
        const valores = [...temasSubTemasEntradas];
        valores[index][event.target.name] = event.target.value;
        setTemasSubTemasEntradas(valores);
    }
    /**
     *
     */
    const handleAddCampos = () => {
        if (temasSubTemasEntradas.length > 3) {
            const notification = {
                title: "Aviso!",
                message: 'El maximo de temas seleccionados son : 5',
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };


            store.addNotification({
                ...notification,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            return;
        }
        if (!(Object.entries(valorTemaSelecionado).length >= 0) && Object.entries(valorTemaSubSelecionado).length === 0) {
            const notification = {
                title: "Aviso!",
                message: 'Para agregar un tema o subtema debe seleccionarlos',
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };


            store.addNotification({
                ...notification,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        } else {
            temasSubTemasEntradas.reduce(function (contador, item) {
                if (item.tema === 'Uno' && Object.entries(item.subtema).length === 0) {
                    const removed = temasSubTemasEntradas.splice(contador, 1);
                    return setTemasSubTemasEntradas(removed);
                }
            }, 0);


            console.log('final', temasSubTemasEntradas)
            setTemasSubTemasEntradas([...temasSubTemasEntradas, {
                tema: valorTemaSelecionado[0],
                subtema: [valorTemaSubSelecionado]
            }]);
            setCargarListTemas(true);
            setMostrarTabla(true);

        }

    }
    /**
     *
     * @param idx
     */
  const handleEliminarCampos = (idx) => {
        const valores = [...temasSubTemasEntradas];
        valores.splice(idx, 1);
        setTemasSubTemasEntradas(valores);
        if(idx === 0 && temasSubTemasEntradas.length === 1 ){
            console.log(idx)
            setMostrarTabla(false);

        }
    }


    return (
        <form onSubmit={handleEnviandoCarga}>
            <ReactNotification></ReactNotification>
            <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full mt-20">
                <div className="md:container md:mx-auto bg-gray-100">
                <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8 ">
                    <div className="border-azul bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mb-6 mt-16">
                        <div className="w-auto mr-10 text-center mt-2">
                            <h3 className="pb-2 border-b mb-3 text-lg  font-black">Cargar Archivo</h3>

                        </div>
                        <div className="hidden lg:flex ml-12 -top-2.5 justify-center">
                            <div className="w-1/3 text-center py-8">
                                <div className="border-r">
                                    <div className="text-grey-darker mb-2">
                                        <div className="p-0.5">
                                            <div
                                                className="bg-white h-10 flex items-center rounded-full shadow-xl border border-rosa cursor-pointer">
                                                <input value={archivoNombre} readOnly disabled
                                                       className="rounded-l-full appearance-none w-96 py-4 px-6  h-12 text-black leading-tight focus:outline-none"
                                                       onChange={onChangeArchivo}/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-sm uppercase text-grey tracking-wide">
                                        Nombre del Archivo
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3 text-center py-8">
                                <div>
                                    <div className="text-grey-darker mb-2">
                                        <div className="rounded-full w-50 mr-60 px-8 py-0">
                                            <label

                                                className="ml-8 h-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-rosa hover:bg-gris">
                                                <svg className="w-16 h-10" fill="currentColor"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 20 20">
                                                    <path
                                                        d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"/>
                                                </svg>
                                                <span className="mt-2 text-base leading-normal text-center">Selecionar archivo</span>
                                                <input accept=".doc,.docx,.txt" type='file' className="hidden" onChange={onChangeArchivo}/>
                                            </label>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full mb-6 lg:mb-0 lg:w-1/2 h-2/6 px-4 flex flex-col">
                            <div
                                className=" border-azul  flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                                <div className="border-b">
                                    <div className="flex justify-between px-6 -mt-1 -mb-px">
                                        <h3 className="text-blue-dark py-4 font-normal text-lg">TEMAS:</h3>
                                        <div className="flex">
                                            <button type="button"
                                                    className="appearance-none py-4 text-blue-dark border-b border-blue-dark mr-3">

                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow flex px-6 py-6 text-grey-darker items-center border-b -mx-4">
                                    <div className="w-2/5 xl:w-1/4 px-4 flex items-center text-center">
                                        <div className="mb-2 pb-2">

                                            {



                                                tema.temas && (
                                                    <select  name="tema"
                                                             className="rounded-l-md w-52 h-10 rounded-lg shadow-lg  tracking-wide border border-rosa cursor-pointer hover:text-azul"
                                                             onChange={cargarSubTemas}>
                                                        <option hidden={true}  defaultChecked={true}   defaultValue="-1">Seleccione un Tema:</option>
                                                        {tema.temas.map((tema) => (
                                                            <option key={tema.idTema}
                                                                    value={tema.idTema}>{tema.descripcion}</option>
                                                        ))}
                                                    </select>

                                                )
                                            }


                                        </div>
                                        <div className="flex w-3/5 md:w/12">
                                            <div className="w-1/2 px-4">
                                                <div className="text-right">
                                                    <div className="mb-2 pb-2">
                                                        {
                                                            subtemasBandeja && (
                                                                <select name="subTema" onChange={handleChangeSubtemas}
                                                                        className="rounded-l-md w-56 h-10 rounded-lg shadow-lg  tracking-wide border border-rosa cursor-pointer hover:text-azul">
                                                                    <option hidden={true}  defaultValue="-1">Seleccione un SubTema:</option>
                                                                    {subtemas.tema.subtemaList.map((tema) => (
                                                                        <option key={tema.idTema}
                                                                                value={tema.idTema}>{tema.descripcion}</option>
                                                                    ))}
                                                                </select>

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className=" ml-48 border-rosa cursor-pointer hover:text-white">
                                            <div className="flex justify-center">
                                                <label
                                                    className="w-30 flex flex-col items-center px-2 py-1  bg-white text-black rounded-lg shadow-lg tracking-wide uppercase border border-rosa cursor-pointer hover:bg-rosa hover:text-white">
                                                    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                                              clipRule="evenodd"/>
                                                    </svg>
                                                    <span className="mt-2 text-base leading-normal">agregar</span>
                                                    <input type='button' className="hidden"
                                                           onClick={() => handleAddCampos()}/>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-4">
                            <div className="border-azul bg-white border-t border-b sm:rounded sm:border shadow">
                                <div className="border-b">
                                    <div className="flex justify-between px-6 -mb-px">
                                        <h3 className="text-blue-dark py-4 font-normal text-lg">TEMAS SELECIONADOS:</h3>
                                    </div>
                                </div>
                                <div>
                                    <div className="text-center px-6 py-4">
                                        <div className="py-8">
                                            {
                                                mostrarTabla?(
                                                    <div className="container mt-0">
                                                        <table
                                                            className="md:table table-bordered table-hover text-center"
                                                            id="tab_logic"
                                                        >
                                                            <thead>

                                                            </thead>
                                                            <thead>
                                                            <tr>
                                                                <th className="px-1 text-center py-0.5 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> #</th>
                                                                <th className="px-1 text-center py-0.5 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Tema</th>
                                                                <th className="px-1 text-center py-0.5 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Subtema</th>
                                                                <th className="px-1 text-center py-0.5 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Opción</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="mr-32 text-center">
                                                            {cargarListTemas &&
                                                            temasSubTemasEntradas.map((item, idx) => (
                                                                <tr id="addr0" key={idx}>
                                                                    <td>{idx}</td>
                                                                    <td className="px-5 py-5 border-b border-gray-200  text-sm">
                                                                        <input
                                                                            className="rounded-l-md w-36 h-10 rounded-lg shadow-lg text-center  tracking-wide border border-rosa cursor-pointer hover:text-azul"
                                                                            type="text"
                                                                            name="tema"
                                                                            disabled
                                                                            value={item.tema}
                                                                            onChange={event => handleChangeInput(idx, event)}
                                                                        />
                                                                    </td>
                                                                    <td className="px-5 py-5 border-b border-gray-200 bg-blue-50 text-sm">
                                                                        <input
                                                                            type="text"
                                                                            className="rounded-l-md w-36 h-10 rounded-lg shadow-lg  text-center  tracking-wide border border-rosa cursor-pointer hover:text-azul"
                                                                            name="subtema"
                                                                            disabled
                                                                            value={item.subtema}
                                                                            onChange={event => handleChangeInput(idx, event)}
                                                                        />
                                                                    </td>
                                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                        <label
                                                                            className="md:w-32 flex flex-col items-center px-2 py-1  bg-white text-black rounded-lg shadow-lg tracking-wide uppercase border border-rosa cursor-pointer hover:bg-rosa hover:text-white">
                                                                            <svg className="h-6 w-6"
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 viewBox="0 0 20 20" fill="currentColor">
                                                                                <path fillRule="evenodd"
                                                                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                                                                      clipRule="evenodd"/>
                                                                            </svg>
                                                                            <span
                                                                                className="mt-4 text-base leading-normal">Eliminar</span>
                                                                            <input type='button' className="hidden"
                                                                                   onClick={(i) => handleEliminarCampos(idx)}/>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                            }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : null

                                            }

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="border-b mt-6 ml-36">
                                <div className="rounded-full w-46 mr-60 px-24 py-0">
                                    <label
                                        className="ml-8 whitespace-nowrap inline-flex items-center ml-80 h-12 justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-azul hover:bg-rosa">
                                        {
                                            !cargandoDatosEnvio && (
                                                <button className="hover:opacity-75 text-white rounded-full mb-14 mr-4 w-8 h-8 cursor-pointer">
                                                    <svg className="h-16 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                         fill="currentColor">
                                                        <path fill-rule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                </button>)
                                        }

                                        <span className="sm:mt-2 text-base leading-normal text-center">Buscar</span>

                                        {
                                            cargandoDatosEnvio && (
                                                <svg className="animate-spin h-6 w-6 text-center mt-8 ml-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                </svg>
                                            )
                                        }
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
                <div className="bg-gray-200 border-t">
                    <div className="container mx-auto px-4">
                        <div className="md:flex justify-between  items-center text-sm">
                            <div className="text-center md:text-left py-3 md:py-4 border-b md:border-b-0">
                                <div className="text-rosa text-center md:mr-4">&copy; 2020 anaLIsA</div>
                            </div>
                            <div className="md:flex md:flex-row-reverse items-center py-4">
                                <div className="text-grey text-center md:mr-4">
                                    <img className="max-h-20 -mr-28" src={logoColombia}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    )

}


export default Cargar